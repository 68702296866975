
.profile-img {
    max-width: 60%;
}

.rounded-circle {
    border:1px solid;
    border-radius:50%;
}

.social .contact-icons {
    font-size: 2rem !important;
}